






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    searchNav: () => import('@/components/business/pc/product/InsTree.vue')
  }
})
export default class InsCmsMenuTree extends Vue {
    @Prop({ default: '30%' }) private width!: string;
    @Prop({ default: '' }) private title!: string;
    @Prop({ default: 0 }) private type!: number; // 类型（ 筛选 0 / 菜单 1 ）

    defaultProps: object = {
      children: 'Children',
      label: 'Name'
    }

    cmsCatTree: object[] = [];

    // 获取cms该id下所有的Category
    getCategoryTree () {
      this.$Api.cms.getCategoryTree({ id: 40101 }).then((result) => {
        this.cmsCatTree = result;
        console.log(result, 'cms该id下所有的Category');
      });
    }

    // 搜寻attr改变
    changeSelectedAttr (item) {
      let id = item.Id;
      let title = item.Name;
      this.$store.dispatch('setIdsFun', { id, title });
      console.log(item, 'cms选择目录改变(菜单)');
    }

    mounted () {
      this.getCategoryTree();
    }
}
